import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyChBAyaU8reVs5iFyHXpY2ByIRGXXkgGEE',
  authDomain: 'codex-metro-betel.firebaseapp.com',
  projectId: 'codex-metro-betel',
  storageBucket: 'codex-metro-betel.appspot.com',
  messagingSenderId: '46966604365',
  appId: '1:46966604365:web:1729866a9f8a7259dc5204',
  measurementId: 'G-T9R4T0HNB5'
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
